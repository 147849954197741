<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeContacts.title') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button
          :router-link="`/tabs/stores/${this.$route.params.id}/contacts/add`"
        >
          <ion-icon :icon="icons.add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="contact in contacts"
        :key="contact.id"
        button
        :router-link="
          `/tabs/stores/${this.$route.params.id}/contacts/${contact.id}`
        "
      >
        <ion-card-header>
          <ion-card-title>
            {{ contact.lastname }}
            {{ contact.firstname }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p v-if="contact.sector">
            {{ $t('labels.sector') }}
            {{ contact.sector.name }}
          </p>
          <p v-if="contact.email">
            {{ $t('labels.email') }}
            <a :href="`mail:${contact.email}`">{{ contact.email }}</a>
          </p>
          <p v-if="contact.phone">
            {{ $t('labels.phone') }}
            <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
          </p>
        </ion-card-content>
      </ion-card>

      <ODNNoContent v-if="contacts.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  IonFab,
  IonFabButton,
  IonIcon,
  toastController,
} from '@ionic/vue';
import { add } from 'ionicons/icons';

import ODNNoContent from '@c/odn-no-content.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    IonFab,
    IonFabButton,
    IonIcon,
    ODNNoContent,
  },
  data() {
    return {
      contacts: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
      icons: {
        add,
      },
    };
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.contacts = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get(
          `/stores/${this.$route.params.id}/contacts`,
          {
            page: this.page,
            limit: this.limit,
            search: this.searchText,
          }
        );

        if (clear) {
          this.contacts = [];
        }

        this.contacts = this.contacts.concat(response.data.items);

        if (this.contacts.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.files.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
  },
};
</script>
